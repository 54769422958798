import React, { Fragment } from 'react'
import styled from 'react-emotion'
import Link from '../components/Link'
import { One, Row, RowGroup } from '../components/Grid'
import SEO from '../components/SEO'
import { H1, H2, Section } from '../components/Style'
import ContactForm from '../components/ContactForm'
import { CONTRAST_COLOR } from '../constants'
import TalksContext from '../components/TalksContext'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import chunk from 'lodash/chunk'
import Layout from '../components/Layout'

const formatDate = dateString => {
  return new Date(dateString).toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  })
}

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/talks",
      "name": "Talks"
    }
  }]
}`

const byYear = events =>
  groupBy(orderBy(events, 'date', 'desc'), ({ date }) =>
    new Date(date).getFullYear()
  )

export default () => (
  <Layout>
    <TalksContext.Consumer>
      {allTalks => (
        <Fragment>
          <SEO
            path="/talks"
            title="Public Speaking – 500Tech"
            description="Public talks, workshops, and master classes by 500Tech"
            ld={breadcrumbs}
          />

          <Section>
            <H1>Public speaking</H1>
          </Section>

          {orderBy(Object.keys(byYear(allTalks)), null, 'desc').map(year => (
            <Fragment>
              <H2>{year}</H2>

              <RowGroup>
                {chunk(byYear(allTalks)[year], 4).map((row, index) => (
                  <Row key={index}>
                    {row.map(talk => (
                      <One key={talk.url}>
                        <EventName>
                          <Link to={talk.url}>{talk.title}</Link>
                          {/*<EnglishLabel>{talk.language}</EnglishLabel>*/}
                        </EventName>
                        <Metadata>
                          {formatDate(talk.date)}, {talk.event}
                        </Metadata>
                        <Metadata>
                          {talk.city}, {talk.country}
                        </Metadata>
                      </One>
                    ))}
                  </Row>
                ))}
              </RowGroup>
            </Fragment>
          ))}

          <ContactForm />
        </Fragment>
      )}
    </TalksContext.Consumer>
  </Layout>
)

const Metadata = styled.div`
  font-size: 0.8rem;
  line-height: 1.5;
`

const EventName = styled.div`
  color: ${CONTRAST_COLOR};
  font-size: 1rem;
  line-height: 1.4;
`
